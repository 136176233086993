import React from "react";
import theme from "theme";
import { Theme, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"iq-test"} />
		<Helmet>
			<title>
				IQ Test in progress
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar />
		<Section box-shadow="10px 10px 10px 5px --color-primary">
			<Override slot="SectionContent" sm-display="grid" />
			<Components.IqTestWrapper>
				<Override
					slot="Option"
					max-width="150px"
					max-height="150px"
					sm-width="25vw"
					sm-height="25vw"
					md-width="30vw"
					md-height="30vw"
					md-padding="8px 0 8px 0"
					lg-width="12vw"
					lg-height="12vw"
					border-radius="5px"
					width="12vw"
					height="12vw"
					box-shadow="0 0 10px 5px --color-lightD2"
					hover-box-shadow="none"
					hover-transform="scale(1.12)"
					hover-transition="all 1s ease 0s"
				/>
				<Override
					slot="OptionsWrapper"
					width="100%"
					justify-items="center"
					md-grid-gap={0}
					grid-gap={0}
					padding="70px 0px 0px 0px"
					md-padding="0 0px 0px 0px"
				/>
				<Override
					slot="Label"
					background="linear-gradient(180deg,rgba(197, 216, 231, 0.3) 0%,transparent 100%) 0 0 no-repeat"
					border-radius="0 0 25px 25px"
					padding="10px 0px 0 0px"
					border-color="--color-primary"
					font="normal 400 17px/1.5 --fontFamily-googlePoppins"
					width="100%"
					color="--secondary"
				>
					A
				</Override>
				<Override slot="Question" sm-margin="0px auto 0px auto" border-radius="15px" id="question" />
				<Override slot="ProgressBar" width="100%">
					<Override slot="Wrapper" background="--color-sandyBrown" border-radius="3px 3px 50% 50%" box-shadow="0 -5px 10px -5px --color-primary" />
					<Override slot="Label" border-color="--color-light">
						1/30
					</Override>
					<Override slot="PGLabel" color="--light" font="500 17px/25.5px Poppins, sans-serif" />
				</Override>
				<Override slot="Introduction" margin="16px 0px 30px 0px" font="--lead" />
				<Override slot="CountDown" color="--vividOrange" font="normal 500 20px/1.5 --fontFamily-googlePoppins">
					Time left: 14:52
				</Override>
				<Override
					slot="OptionWrapper"
					display="grid"
					border-radius="5px"
					justify-items="center"
					margin="0px 0px 10px 0px"
				/>
			</Components.IqTestWrapper>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://iqresearches.com/iq-test/"} rel={"canonical"} place={"endOfHead"} rawKey={"661e641b2f35efb8d2416a0f"} />
		</RawHtml>
	</Theme>;
});